import React from 'react';
import PropTypes from 'prop-types';
import Animated from "animated/lib/targets/react-dom";
import Interactable from "react-interactable/noNative";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import { spring, AnimatedSwitch } from 'react-router-transition';
import {withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SwipeableViews from 'react-swipeable-views';
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import * as animationData from './animations/cardIn'
import * as animationDataSuccess from './animations/cardSuccess'
import * as animationDataFailed from './animations/cardFailed'
import Lottie from 'react-lottie';
import {EditLocationSharp} from "@material-ui/icons";

function mapStyles(styles) {
    return {
        opacity: styles.opacity,
        transform: `translateX(${styles.offset})`,

    };
}
function getBin() {
    const cardnumber = document.getElementById("cardNumber").value;

    return cardnumber.substring(0,6);
}

function validateMonth(month) {
    var re = /0[1-9]|1[0-2]/;
    return re.test(String(month).toLowerCase());
}

function validateYear(year) {
    var theYear = parseFloat(year);
    var today = new Date();
    var actualYear = today.getFullYear();
    if (theYear >= actualYear && theYear <= 2030){
        return true
    }else {
        return false
    }
}

function validateSecurityCode(code) {
    var re = /^[0-9]{3,4}$/;
    var re = /^[0-9]{3,4}$/;
    return re.test(String(code).toLowerCase());
}

function validateCreditCard(cardNumber) {
    var re = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
    return re.test(String(cardNumber).toLowerCase());
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
    return spring(val, {
        stiffness: 330,
        damping: 22,
    });
}

// child matches will...
const bounceTransition = {
    // start in a transparent, upscaled state
    atEnter: {
        opacity: 0,
        scale: 1.2,
    },
    // leave in a transparent, downscaled state
    atLeave: {
        opacity: bounce(0),
        scale: bounce(0.8),
    },
    // and rest at an opaque, normally-scaled state
    atActive: {
        opacity: bounce(1),
        scale: bounce(1),
    },
};


let posX = new Animated.Value(0);

let styles = {
    container: {
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "stretch",
        overflow: "hidden",

    },
    drawerContainer: {
        width: '100vw',
        position: "absolute",
        left: 0,
       /* bottom: 0,*/
        overflow: "hidden",
        zIndex: 3000,
        height: '100vh'
       /* backgroundColor: '#3591FF',*/
    },
    content: {
        backgroundColor: "white",
        flexGrow: 1,
        position: "relative"
    },
    panelContainer: {
        width: window.innerWidth,
        height: 'auto',
        position: "relative",
        transform: 'translateY(calc(100% - 80px))',
        msTransform: 'translateY(-ms-calc(100% - 80px))',
        WebkitTransform: 'translateY(-webkit-calc(100% - 80px))',
        MozTransform: 'translateY(-ms-calc(100% - 80px))',
        OTransform: 'translateY(-o-calc(100% - 80px))',
    },

    leftPanel: {
        width: '100%',
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        maxWidth: '1000px',
        height: "auto",
        zIndex: 2000,
        flex: 1,
        position: "absolute",
        right: 0,
        left: 0,
        margin: 'auto',
        display: 'block',
        backgroundColor: '#00426B',
    },
    successPanel: {
        width: '100%',
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        maxWidth: '1000px',
        height: "calc(100vh - 60px)",
        zIndex: 2000,
        flex: 1,
        position: "absolute",
        right: 0,
        left: 0,
        margin: 'auto',
        display: 'block',
        backgroundColor: '#00426B',
    },
    toggle: {
        fontSize: 30,
    },
    inter: {
        position: 'fixed',
        bottom: 0,
        zIndex: 1400,
    }

};

export default withRouter( class SlideUpPanel extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            panelOpen: false,
            panelHeight: 0,
            toggleStyle: {},
            value: 0,
            amount: '',
            product: '',
            phone: '',
            email: '',
            docType: '',
            docTypes: [],
            cardNumber:'',
            securityCode:'',
            cardExpirationMonth:'',
            cardExpirationYear:'',
            cardholderName:'',
            docNumber:'',
            installments:[],
            installment: '',
            paymentMethod: '',
            invalidCard: false,
            payOptions: '',
            isStopped: false,
            isPaused: false,
            defaultOptions : {
                loop: false,
                autoplay: false,
                animationData: animationData.default,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
            },
            success: false,
            failed: false,
            pending: false,
            animMessageShown: false,
        };
    }

    refreshHeight = () =>{
        setTimeout(() => {
            this.setState({
                panelHeight: this.panelRef.offsetHeight,
                toggleStyle: {
                    ...styles.toggle,
                    transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                    WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                }

            });
            this.componentRef.snapTo({index: 1});
        },100);
    };

    handleChangeIndex = index => {

        const oldIndex = this.state.value;
        this.setState({
            value: index,
        }, () => {
            if (index === 1) {
                if (!(this.state.amount > 0)) {
                    this.setState({
                        value: oldIndex,
                        error: true
                    });
                }
                else {
                    this.refreshHeight();
                }
            }
            else if (index === 2) {
                if (!this.state.product) {
                    this.setState({
                        value: oldIndex,
                        error: true
                    });
                }
                else {
                    this.refreshHeight();
                }
            }
            else if (index === 3) {
                if (!this.state.phone) {
                    this.setState({
                        value: oldIndex,
                        error: true
                    });
                }
                else {
                    this.refreshHeight();
                }
            }
            else if (index === 4) {
                this.refreshHeight();
            }
            else if (index === 5) {
                if (!validateEmail(this.state.email) || !this.state.cardholderName) {
                    this.setState({
                        value: oldIndex,
                        error: true
                    });
                }
                else {
                    this.refreshHeight();
                }
            }
            else if (index === 6) {
                if (!validateCreditCard(this.state.cardNumber) || !validateSecurityCode(this.state.securityCode || this.state.invalidCard )) {
                    this.setState({
                        value: oldIndex,
                        error: true
                    });
                }
                else {
                    this.refreshHeight();
                }
            }
            else if (index === 7) {
                if (!validateMonth(this.state.cardExpirationMonth) || !validateYear(this.state.cardExpirationYear || this.state.cardNumber)) {
                    this.setState({
                        value: oldIndex,
                        error: true
                    });
                }
                else {
                    this.refreshHeight();
                    window.Mercadopago.getInstallments({
                        "bin": getBin(),
                        "amount": this.state.amount
                    }, (status, res) => {
                        console.log(status, res);
                        if (status === 200){
                            for (let i = 0; i < res.length; i++) {
                                let payOption = res[i]
                                if (payOption.merchant_account_id === '8dd5092e6023423c4256223193047422' ||
                                    payOption.merchant_account_id === '5b76046e9ceb683e37061eaa60c3dc1c' ||
                                    payOption.merchant_account_id === '06aace69dbb544da8958944de53f92d6' ||
                                    payOption.merchant_account_id === '8d3266fa365ad74b0a2e6621ed77e759' ||
                                    payOption.merchant_account_id === '7dfb838ac97909c3dafc5210606c3420'
                                ){
                                    this.setState({
                                        installments: payOption.payer_costs,
                                        payOptions: payOption,
                                    })
                                    break
                                }
                                else if(i === res.length - 1){
                                    this.setState({
                                        value: oldIndex-1,
                                        error: true
                                    });
                                }
                            }

                        }
                        else {
                            this.setState({
                                value: oldIndex-1,
                                error: true
                            });
                        }
                    })
                }
            }
            else if (index === 8) {
                if ( !this.state.docType || !this.validateDoc(this.state.docNumber)) {
                    this.setState({
                        value: oldIndex,
                        error: true
                    });
                }
                else {

                    this.refreshHeight();
                }
            }

            else if (index === 9 && index < oldIndex) {
                this.setState({
                    value: oldIndex,
                });
            }
            else if (index === 10 && index > oldIndex) {
                this.refreshHeight();
            }
            else if (index === 10 && index < oldIndex) {
                this.setState({
                    value: oldIndex,
                });
            }
            /*else if (index < oldIndex) {
                this.setState({
                    value: oldIndex,
            });
            }*/
        });
    };
    handleChange = name => (event) => {
        var context = this;
        this.setState({
            [name]: event.target.value,
            error: false,
        });
        if (name === 'cardNumber'){

          this.guessingPaymentMethod(event)

        }
    };

    guessingPaymentMethod = (event) => {
        var bin = getBin();
        var context = this;
        if (event.type == "keyup") {
            if (bin.length >= 6) {
                window.Mercadopago.getPaymentMethod({
                    "bin": bin
                }, context.setPaymentMethodInfo);
            }
        } else {
            setTimeout(function() {
                if (bin.length >= 6) {
                    window.Mercadopago.getPaymentMethod({
                        "bin": bin
                    }, context.setPaymentMethodInfo);
                }
            }, 100);
        }
    };

    setPaymentMethodInfo = (status, response) => {
        if (status == 200) {
            const paymentMethodElement = document.querySelector('input[name=paymentMethodId]');
            console.log(response);
            this.setState({
                paymentMethod: response[0]
            });
            if (paymentMethodElement) {
                paymentMethodElement.value = response[0].id;
            } else {
                const input = document.createElement('input');
                input.setattribute('name', 'paymentMethodId');
                input.setAttribute('type', 'hidden');
                input.setAttribute('value', response[0].id);
                var form = document.querySelector('#pay');
                form.appendChild(input);
            }
        } else {
            this.setState({
                invalidCard: true,
            });

        }
    };

    validateDoc = (doc) =>{
        var docType = this.state.docTypes.find(x => x.id === this.state.docType);
        if (doc.length >= docType.min_length && doc.length <= docType.max_length){
            return true
        }
        else {return false}
    };

    toggleRotation = (height) => {
        if (height < 80){
            height = 81;
        }
        return posX.interpolate({
            inputRange: [0, (80 - height) * -1],
            outputRange: [ "0deg", "180deg"]
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged = () =>  {
        console.log("ROUTE CHANGED");
        setTimeout(() => {
            console.log(this.panelRef.offsetHeight);
            this.setState({
                panelHeight: this.panelRef.offsetHeight,
                toggleStyle: {
                    ...styles.toggle,
                    transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                    WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                }

            })
        },1000);

    };


    componentWillReceiveProps(nextProps, nextContext) {


        if (nextProps.login !== this.props.login) {
                setTimeout(() => {
                    this.setState({ value: 1 });
                    console.log(this.panelRef.offsetHeight);
                    this.setState({
                        panelHeight: this.panelRef.offsetHeight,
                        toggleStyle: {
                            ...styles.toggle,
                            transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                            WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                        }

                    })

                },500);
            }

    }


    componentDidMount() {
        const context = this;
        window.Mercadopago.setPublishableKey('APP_USR-345d8a3b-55df-41b1-98f8-15cb2b2092d5');

        window.Mercadopago.getIdentificationTypes(function (status, res) {
            console.log(res);
            context.setState({
                docTypes: res,
            })
        });

        setTimeout(()=>{
            this.setState({
                panelHeight: this.panelRef.offsetHeight,
                toggleStyle: {
                    ...styles.toggle,
                    transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                    WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
                }

            })
        },500);

       this.setState({
           panelHeight: this.panelRef.offsetHeight,
           toggleStyle: {
               ...styles.toggle,
               transform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
               WebkitTransform: [{ rotate: this.toggleRotation(this.panelRef.offsetHeight) }],
           }

       })
    }

    toggleOpen = () => {
        if (this.state.panelOpen){
            this.componentRef.snapTo({index: 0});
            this.setState({panelOpen: false})
        } else {
            this.componentRef.snapTo({index: 1});
            this.setState({panelOpen: true})
        }
    };
    onPrevious = () => {
        this.handleChangeIndex(this.state.value - 1);
    };
    onNext = ()=> {
        this.handleChangeIndex(this.state.value + 1);
        /*if (this.state.value < 1){
            if (this.state.amount){
                this.handleChangeIndex(1);
            }
            else {
                this.setState({
                    error: true,
                })
            }
        }
        else if (this.state.value < 2){
            if (this.state.product){
                this.handleChangeIndex(2);
            }
            else {
                this.setState({
                    error: true,
                })
            }
        }
        else if (this.state.value < 3){
            if (this.state.phone){
                this.handleChangeIndex(3);
            }
            else {
                this.setState({
                    error: true,
                })
            }
        }
        else if (this.state.value < 4){
            this.handleChangeIndex(4);
        }*/
    };

    onCompleteLottie = () =>{

        if (!this.state.success && !this.state.failed && !this.state.pending){
            this.lottie.stop();
            this.lottie.play();
        }
        else if(this.state.success && !this.state.animMessageShown){
            this.setState({
                defaultOptions : {
                    loop: false,
                    autoplay: false,
                    animationData: animationDataSuccess.default,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                },
                animMessageShown: true
            });
            this.lottie.stop();
            this.lottie.play();
        }
        else if(this.state.failed && !this.state.animMessageShown){
            this.setState({
                defaultOptions : {
                    loop: false,
                    autoplay: false,
                    animationData: animationDataFailed.default,
                    rendererSettings: {
                        preserveAspectRatio: 'xMidYMid slice'
                    }
                },
                animMessageShown: true
            });
            this.lottie.stop();
            this.lottie.play();
        }
        else if(this.state.pending && !this.state.animMessageShown){
            this.setState({
                animMessageShown: true
            });
            this.lottie.stop();
            this.lottie.play();
        }
        else if (this.state.animMessageShown){
            this.handleChangeIndex(10);
        }

    };

    onBuySubmit = () =>{

        if (this.state.installment){
            this.handleChangeIndex(9);
            setTimeout( () => {
                this.lottie.play();
            },100);

            var $form = document.querySelector('#pay');
            console.log($form);
            window.Mercadopago.createToken($form, (status, res) => {
                console.log(res);
                var payment_data = {};
                if(this.state.payOptions.processing_mode === 'aggregator'){
                    payment_data = {
                        transaction_amount: parseFloat(this.state.amount),
                        token: res.id,
                        description: this.state.product,
                        installments: this.state.installment,
                        payment_method_id: this.state.payOptions.payment_method_id,
                        issuer_id: this.state.payOptions.issuer.id,
                        payer: {
                            email: this.state.email,
                        },
                        additional_info: {
                            payer: {
                                phone: {
                                    number: this.state.phone
                                },
                            },
                        }
                    };
                }else {
                    payment_data = {
                        transaction_amount: parseFloat(this.state.amount),
                        token: res.id,
                        description: this.state.product,
                        installments: this.state.installment,
                        payment_method_id: this.state.payOptions.payment_method_id,
                        issuer_id: this.state.payOptions.issuer.id,
                        processing_mode: this.state.payOptions.processing_mode,
                        payment_method_option_id: this.state.payOptions.agreements[0].merchant_accounts[0].payment_method_option_id,
                        payer: {
                            email: this.state.email,
                        },
                        additional_info: {
                            payer: {
                                phone: {
                                    number: this.state.phone
                                },
                            },
                        }
                    };
                }
                if (res.id){
                    fetch('https://us-central1-simmons-be8a2.cloudfunctions.net/pay', {
                        method: 'POST', // or 'PUT'
                        body: JSON.stringify(payment_data), // data can be `string` or {object}!
                        headers:{
                            'Content-Type': 'application/json'
                        }
                    }).then(res => {
                        res.json().then((val) => {
                            console.log(val);
                            if (val.response.status === 'approved'){
                                this.setState({
                                    success: true,
                                });
                                this.props.history.push('success');
                            }
                            else if (val.response.status === 'rejected'){
                                this.setState({
                                    failed: true,
                                });
                            }
                            else if (val.response.status === 'in_process'){
                                this.setState({
                                    pending: true,
                                });
                            }

                        }).catch( (error) => {
                            console.log(error);
                            this.setState({
                                failed: true,
                            });
                        });
                    }).catch( (error) => {
                        console.log(error);
                        this.setState({
                            failed: true,
                        });
                    });
                }else {
                    this.setState({
                        failed: true,
                    });
                }




                console.log(payment_data);
            });
        }
        else {
            this.setState({
                error: true
            });
        }


    };

    onBuy = () =>{
        this.handleChangeIndex(4);
        /*var data = {price: this.state.amount, product: this.state.product,
            phone: this.state.phone};

        fetch('https://us-central1-simmons-be8a2.cloudfunctions.net/buy', {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(res => {
            res.json().then(function (val) {
                console.log(val);
                window.location.href = val.link;
            }).catch(function (error) {
                console.log(error)
            });
        }).catch(function (error) {
            console.log(error)
        });*/



    };

    render() {
        const theme = createMuiTheme({
            palette: {
                  primary: {
                      // light: will be calculated from palette.primary.main,
                      main: '#ffffff',
                      // dark: will be calculated from palette.primary.main,
                      // contrastText: will be calculated to contrast with palette.primary.main
                      contrastText: '#000000'
                  },
                secondary: {
                    main: '#ff0013',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#ffffff'
                },
                error: {
                    main: '#fff',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                    contrastText: '#ff0013'
                }
            },
            typography: { useNextVariants: true },
        });
        return (
            <MuiThemeProvider theme={theme}>
                        <Interactable.View
                            ref={el => (this.componentRef = el)}
                            animatedValueY={posX}
                            boundaries={{top: 80 - this.state.panelHeight, bottom: 0, bounce: 1}}
                            snapPoints={[{ y: 0 }, { y: 80 -this.state.panelHeight }]}
                            verticalOnly={true}
                            style={styles.inter}
                        >
                            <div style={styles.panelContainer}   >
                                <Paper className={'panel'} style={this.state.success ? styles.leftPanel : styles.leftPanel} variant="contained" color="primary" ref={el => (this.panelRef = el)}>
                                    <div onClick={this.toggleOpen}>
                                        <Animated.div  style={this.state.toggleStyle}><i style={{color: '#ffffff'}} className={'ion-md-arrow-dropup'}/></Animated.div>
                                        <p className={'startLoginPTitle'}> {
                                            this.state.success && '💳 Listo'
                                        }
                                            {this.state.failed && '💳 Error'}
                                            {this.state.pending && '💳 Pendiente'}
                                            {!this.state.failed && !this.state.success && !this.state.pending && '💳 Pagar'}
                                        </p>
                                    </div>
                                    <form action="" method="post" id="pay" name="pay">
                                        <SwipeableViews
                                            index={this.state.value}
                                            onChangeIndex={this.handleChangeIndex}
                                        >
                                            <div>
                                                <p className={'startLoginPTitle'}> {
                                                    'Ingresá el monto'
                                                } </p>
                                                <TextField
                                                    value={this.state.amount}
                                                    onChange={this.handleChange('amount')}
                                                    error={this.state.error}
                                                    label="Monto"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    type="number"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />


                                            </div>
                                            <div>
                                                <p className={'startLoginPTitle'}> {
                                                    'Ingresá el producto'
                                                } </p>
                                                <TextField
                                                    value={this.state.product}
                                                    onChange={this.handleChange('product')}
                                                    error={this.state.error}
                                                    label="Producto"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />


                                            </div>
                                            <div>
                                                <p className={'startLoginPTitle'}> {
                                                    'Ingresá tu numero de teléfono'
                                                } </p>
                                                <TextField
                                                    value={this.state.phone}
                                                    onChange={this.handleChange('phone')}
                                                    error={this.state.error}
                                                    label="Teléfono"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    type="tel"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />


                                            </div>
                                            <div>
                                                <p className={'startLoginPTitle'}> {
                                                    'Confirmar y pagar'
                                                } </p>
                                                {this.state.value === 3 &&
                                                <p className={'startLoginPTitle'}> {
                                                    'Monto:'
                                                }
                                                    <span>
                                                        {'$' + this.state.amount}
                                                    </span>
                                                </p>
                                                }

                                                {this.state.value === 3 &&
                                                <p className={'startLoginPTitle'}> {
                                                    'Producto:'
                                                }
                                                    <span>
                                                        {this.state.product}
                                                    </span>
                                                </p>
                                                }
                                                {this.state.value === 3 &&
                                                <p className={'startLoginPTitle'}> {
                                                    'Telefono:'
                                                }
                                                    <span>
                                                        {this.state.phone}
                                                    </span>
                                                </p>
                                                }

                                                <Button onClick={this.onBuy}  variant="contained" color="primary"
                                                        className={"loginButton modalButton loginStartButton buyButton2 "}>
                                                    PAGAR
                                                </Button>

                                            </div>
                                            <div>
                                                    <TextField
                                                        id="email" name="email"
                                                        type="email"
                                                        value={this.state.email}
                                                        onChange={this.handleChange('email')}
                                                        error={this.state.error}
                                                        label="Email"
                                                        className="fieldModal loginDetails"
                                                        margin="normal"
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: '',
                                                                focused: 'inputCustomLogin',
                                                            },
                                                        }}
                                                        InputProps={{
                                                            classes: {
                                                                root: 'inputCustomLogin',
                                                                focused: 'inputCustomLogin',
                                                                notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                                error: 'errorInput',
                                                            },
                                                        }}
                                                    />
                                                <TextField
                                                    type="text" id="cardholderName"
                                                    value={this.state.cardholderName}
                                                    onChange={this.handleChange('cardholderName')}
                                                    error={this.state.error}
                                                    label="Nombre del titular"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    inputProps={{'data-checkout': "cardholderName",}}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />


                                                    {/*<fieldset>
                                                        <ul>


                                                            <li>
                                                                <label htmlFor="docType">Document type:</label>
                                                                <select id="docType" data-checkout="docType"></select>
                                                            </li>

                                                        </ul>
                                                        <input type="hidden" name="paymentMethodId"/>
                                                        <input type="submit" value="Pay!"/>
                                                    </fieldset>*/}


                                            </div>
                                            <div>
                                                <TextField
                                                    type="number" id="cardNumber"
                                                    placeholder="4509 9535 6623 3704"
                                                    value={this.state.cardNumber}
                                                    onChange={this.handleChange('cardNumber')}
                                                    onPaste={() =>{return false}} onCopy={() =>{return false}}
                                                    onCut={() =>{return false}} onDrag={() =>{return false}}
                                                    onDrop={() =>{return false}} autoComplete='off'
                                                    error={this.state.error}
                                                    label="Numero de tarjeta"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    inputProps={{'data-checkout': "cardNumber",}}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />
                                                <TextField
                                                    type="number" id="securityCode"
                                                    placeholder="123"
                                                    value={this.state.securityCode}
                                                    onChange={this.handleChange('securityCode')}
                                                    onPaste={() =>{return false}} onCopy={() =>{return false}}
                                                    onCut={() =>{return false}} onDrag={() =>{return false}}
                                                    onDrop={() =>{return false}} autoComplete='off'
                                                    error={this.state.error}
                                                    label="Codigo de seguridad"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    inputProps={{'data-checkout': "securityCode",}}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />


                                            </div>
                                            <div>
                                                <TextField
                                                    type="number" id="cardExpirationMonth"
                                                    placeholder="12"
                                                    value={this.state.cardExpirationMonth}
                                                    onChange={this.handleChange('cardExpirationMonth')}
                                                    onPaste={() =>{return false}} onCopy={() =>{return false}}
                                                    onCut={() =>{return false}} onDrag={() =>{return false}}
                                                    onDrop={() =>{return false}} autoComplete='off'
                                                    error={this.state.error}
                                                    label="Mes de vencimiento"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    inputProps={{'data-checkout': "cardExpirationMonth",}}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />
                                                <TextField
                                                    type="number" id="cardExpirationYear"
                                                    placeholder="2020"
                                                    value={this.state.cardExpirationYear}
                                                    onChange={this.handleChange('cardExpirationYear')}
                                                    onPaste={() =>{return false}} onCopy={() =>{return false}}
                                                    onCut={() =>{return false}} onDrag={() =>{return false}}
                                                    onDrop={() =>{return false}} autoComplete='off'
                                                    error={this.state.error}
                                                    label="Año de vencimiento"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    inputProps={{'data-checkout': "cardExpirationYear",}}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />


                                            </div>
                                            <div>
                                                <FormControl variant="outlined" className={'inputCustomLogin fieldModal loginDetails'}>
                                                    <InputLabel
                                                        ref={ref => {
                                                            this.InputLabelRef = ref;
                                                        }}
                                                        htmlFor="outlined-age-simple"
                                                    >
                                                        Tipo de documento
                                                    </InputLabel>
                                                    <Select
                                                        /*native={true}*/
                                                        value={this.state.docType}
                                                        onChange={this.handleChange('docType')}
                                                        id="docType"
                                                        inputProps={{
                                                            'data-checkout': "docType",
                                                            classes: {
                                                                root: 'inputCustomLogin',
                                                                select: 'inputCustomLogin',
                                                            },
                                                        }}
                                                        input={
                                                            <OutlinedInput
                                                                className={'inputCustomLogin'}
                                                                labelWidth={180}
                                                            />
                                                        }
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {this.state.docTypes.map((result, idx) =>(
                                                            <MenuItem key={idx} value={result.id}>{result.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    type="number" id="docNumber"
                                                    placeholder="12345678"
                                                    value={this.state.docNumber}
                                                    onChange={this.handleChange('docNumber')}
                                                    error={this.state.error}
                                                    label="Numero de documento"
                                                    className="fieldModal loginDetails"
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        classes: {
                                                            root: '',
                                                            focused: 'inputCustomLogin',
                                                        },
                                                    }}
                                                    inputProps={{'data-checkout': "docNumber",}}
                                                    InputProps={{
                                                        classes: {
                                                            root: 'inputCustomLogin',
                                                            focused: 'inputCustomLogin',
                                                            notchedOutline: this.state.error ? 'errorInput' : 'inputCustomLogin',
                                                            error: 'errorInput',
                                                        },
                                                    }}
                                                />
                                                <input type="hidden" name="paymentMethodId"/>
                                            </div>
                                            <div>
                                                <FormControl variant="outlined" className={'inputCustomLogin fieldModal loginDetails'}>
                                                    <InputLabel
                                                        ref={ref => {
                                                            this.InputLabelRef = ref;
                                                        }}
                                                        htmlFor="outlined-age-simple"
                                                    >
                                                        Cuotas
                                                    </InputLabel>
                                                    <Select
                                                        value={this.state.installment}
                                                        onChange={this.handleChange('installment')}
                                                        inputProps={{
                                                            classes: {
                                                                root: 'inputCustomLogin',
                                                                select: 'inputCustomLogin',
                                                            },
                                                        }}
                                                        input={
                                                            <OutlinedInput
                                                                className={'inputCustomLogin'}
                                                                labelWidth={90}
                                                            />
                                                        }
                                                    >
                                                        <MenuItem value="">
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {this.state.installments.map((result, idx) =>(
                                                            <MenuItem key={idx} value={result.installments}>

                                                                {this.state.payOptions.processing_mode === 'gateway' && result.installments === 1 && result.recommended_message }


                                                                {this.state.payOptions.processing_mode === 'gateway' && result.installments === 7 && '12 cuotas de $ '+ (parseFloat(this.state.amount)/12).toFixed(2) + ' ( $ ' + parseFloat(this.state.amount).toFixed(2) + ') ' }
                                                                {this.state.payOptions.processing_mode === 'gateway' && result.installments === 8 && '18 cuotas de $ '+ (parseFloat(this.state.amount)/18).toFixed(2) + ' ( $ ' + parseFloat(this.state.amount).toFixed(2) + ') '}
                                                                {this.state.payOptions.processing_mode === 'gateway' && result.installments === 13 && '3 cuotas de $ '+ (parseFloat(this.state.amount)/3).toFixed(2) + ' ( $ ' + parseFloat(this.state.amount).toFixed(2) + ') '}
                                                                {this.state.payOptions.processing_mode === 'gateway' && result.installments === 16 && '6 cuotas de $ '+ (parseFloat(this.state.amount)/6).toFixed(2) + ' ( $ ' + parseFloat(this.state.amount).toFixed(2) + ') '}

                                                                {this.state.payOptions.processing_mode !== 'gateway' && result.recommended_message}

                                                                {/*{result.recommended_message}*/}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>


                                                <Button onClick={this.onBuySubmit}  variant="contained" color="primary"
                                                        className={"loginButton modalButton loginStartButton buyButton2 "}>
                                                    PAGAR
                                                </Button>
                                            </div>
                                            <div>
                                                {this.state.value === 9 &&
                                                <Lottie
                                                    ref={(ref) => this.lottie = ref}
                                                    isStopped={this.state.isStopped}
                                                    isPaused={this.state.isPaused}
                                                    width={200}
                                                    options={this.state.defaultOptions}
                                                    eventListeners={[{eventName: 'complete',callback: this.onCompleteLottie}]}
                                                />}
                                            </div>
                                            <div>
                                                <p className={'startLoginPTitle'}>
                                                    {this.state.success && '¡Tu pago fue procesado con exito! Muchas gracias'}
                                                    {this.state.failed && 'Hubo un problema con tu pago y este no se pudo procesar'}
                                                    {this.state.pending && 'Estamos procesando el pago. En menos de 2 días hábiles te enviaremos por e-mail el resultado.'}
                                                </p>
                                            </div>


                                        </SwipeableViews>
                                    </form>
                                        {this.state.value !== 9 && this.state.value !== 10 &&
                                        <div className={'bottomContainer'}>
                                            <div style={{marginLeft: '5%'}} className={this.state.value === 0 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 1 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 2 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 3 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 4 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 5 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 6 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 7 ? 'pageBall ballSelected' : 'pageBall'}/>
                                            <div className={this.state.value === 8 ? 'pageBall ballSelected' : 'pageBall'}/>

                                            {
                                                this.state.value !== 3 && this.state.value !== 8 && this.state.value !== 9 &&
                                                <Button onClick={this.onNext} color="primary" className={'nextButton'}>
                                                    {'Siguiente'}
                                                </Button>
                                            }

                                            {
                                                this.state.value !== 0 && this.state.value !== 3 && this.state.value !== 8 && this.state.value !== 9 &&
                                                <Button onClick={this.onPrevious} color="primary" className={'previousButton'}>
                                                    {'Anterior'}
                                                </Button>
                                            }



                                        </div>
                                        }

                                </Paper>
                            </div>
                        </Interactable.View>
            </MuiThemeProvider>

        )
    }
})
