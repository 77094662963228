import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SlideUpPanel from "./SlideUpPanel";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Switch, Route, withRouter, Link } from "react-router-dom";
import patagoniaLogo from './images/LOGO SIMMONS STORE Mayo2019_transparente_BLANCO.png';
import mercadoPago from './images/logo_mercado_pago.png';

function mapStyles(styles) {
    return {
        opacity: styles.opacity,
        transform: `scale(${styles.scale})`,
    };
}




export default withRouter( class AppComponent extends React.Component {

    static propTypes = {
        user: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            search: '',
        };
    }

    componentDidMount() {

    }
    componentWillReceiveProps(nextProps, nextContext) {

    }

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };




    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#fff',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });
        return (
            <div>
               {/* <div className={'upper'}>
                    <p>
                        *APLICA TYC, CONSÚLTELOS <i className="ion ion-logo-facebook"></i><span onClick={() => {this.props.history.push('privacypolicy')}}>@BBCCERVECERIA</span>, PROHÍBESE EL EXPENDIO DE BEBIDAS EMBRIAGANTES A MENORES DE EDAD. EL EXCESO DE ALCOHOL ES PERJUDICIAL PARA LA SALUD.
                        <img className={'upperSymbols'} alt={'upperSymbols'} src={upperSymbols}/>
                    </p>
                </div>*/}
                <div className={'logoContainer'}>
                    <img className={'logo'} src={patagoniaLogo}/>
                </div>

                <div className={'logoContainer'}>
                    <span className={'mercadoPagoText'}>powered by</span>
                    <img className={'logo mepaLogo'} src={mercadoPago}/>
                </div>

                <p className={'startLoginPTitle introP'}>Realizá tu pago</p>


                <SlideUpPanel />
            </div>
        )
    }
})