import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './App.css';
import { create } from 'jss';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import $ from 'jquery';
import AppComponent from "./AppComponent";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';


const jss = create({
    ...jssPreset(),
    // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
    insertionPoint: document.getElementById('jss-insertion-point'),
});


export default class App extends React.Component {


    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        $("#loading").fadeOut();
        $("#loading .object").delay(700).fadeOut("slow");
        $('.fadeInOnLoad').delay(700).fadeTo("slow", 1);
    }


    render() {
        const theme = createMuiTheme({
            palette: {
                primary: {
                    // light: will be calculated from palette.primary.main,
                    main: '#3591FF',
                    // dark: will be calculated from palette.primary.main,
                    // contrastText: will be calculated to contrast with palette.primary.main
                }
            },
            typography: { useNextVariants: true },
        });
        return (
            <Router>
                <StylesProvider jss={jss}>
                    <MuiThemeProvider theme={theme}>
                        <div className="App">
                            <div id="loading">
                                <div id="loading-center">
                                    <div id="loading-center-absolute">
                                        <div className="object" id="object_four"></div>
                                        <div className="object" id="object_three"></div>
                                        <div className="object" id="object_two"></div>
                                        <div className="object" id="object_one"></div>
                                    </div>
                                </div>
                            </div>
                            <Switch>
                                <Route
                                    path='/'
                                    render={(props) =>

                                        <div className={''}>
                                            <AppComponent/>
                                        </div>}
                                />
                            </Switch>
                        </div>
                    </MuiThemeProvider>
                </StylesProvider>
            </Router>
        )
    }
}